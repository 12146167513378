import React, { useRef, useState, useEffect, useCallback } from "react";
import Layout from "@components/layout";
import { GatsbyImage } from "gatsby-plugin-image"

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import Animation modules
import { motion } from "framer-motion"

// Import SVG
import hatena_icon from '@images/hatena_icon.svg'; // ？マークの代わりのダミー




import topphone from '@images/phone-image.webp';
import topimage01 from '@images/top-image01_2.webp';
import beginnerImg01 from '@images/about_img01.webp';
import beginnerImg01_1 from '@images/about_img01_01.png';
import beginnerImg01_2 from '@images/about_img01_02.png';
import beginnerImg01_sp from '@images/about_img01_sp.webp';
import toptokuchou01 from '@images/top_tokuchou01.webp';
import toptokuchou02 from '@images/top_tokuchou02.webp';
import toptokuchou03 from '@images/top_tokuchou03.webp';
import toptokuchou04 from '@images/top_tokuchou04.webp';
import toptokuchou05 from '@images/top_tokuchou05.webp';

// 関数呼び出し
import { FixedFoot } from "@components/footer.js";
import { SeoContents } from "@components/seo-data.js" // Seo情報設定


const PropBeginner = () => {
  let fixedItem = FixedFoot( 'nomal' ); // 固定フッター
 
  // Seo情報設定
  const pathName = 'beginner';
  const seoTag = SeoContents({ Path:pathName });

  let setArray;
  let pushArray = [];
  const pickupPath = "/";
  const LinkBeginner = "/beginner#how";
  const LinkGuidance_two = "/guidance#twostep";
  const LinkGuidance_cost = "/guidance#cost";
  const LinkAbout = "/about#how";

  
  
  return (
  <>
    <Layout>
      {seoTag}
      <div className="bg-[#F6F6F6] md:pb-[70px] pb-2">
        <div className="px-5 max-w-xl mx-auto md:max-w-5xl relative md:h-[300px] xl:overflow-visible overflow-hidden">
          <div className="pb-10 pt-0 md:pt-10  pt-0  max-w-[800px] md:absolute md:left-5 md:top-[0%]">
            <h2 className="md:text-5xl text-3xl font-black mb-10 text-left pt-5">初めての方へ</h2>
            <h3 className="md:text-3xl text-2xl font-black mb-5 text-left  text-[#1F55A6]">Just tradeについて</h3>
            <p className="md:text-base text-base  font-black  md:max-w-[480px] xl:max-w-[600px]">
              2 STEPのチャレンジをクリアすることで<br></br>
              Just tradeの資金最大4,000万円の取引ができるチャンス！
            </p>
          </div>

          <div className="max-w-[650px] md:absolute md:right-[-23%] md:top-10">
            <motion.div className=" mx-auto text-center md:block md:mt-10 xl:mt-[0px] md:ml-[200px] xl:ml-[40px]" initial={{ opacity:0
              ,x:100 }} whileInView={{ opacity:1 ,x:0 }} transition={{ duration: 1 }} viewport={{ once: true }}>
                <img className="mb-5 mx-auto h-auto" alt="" src={topimage01}/>
            </motion.div>
          </div>
        
        </div>
      </div>
      
      {/* サブメニュー */}
      <div className="bg-[#F6F6F6] "><a id="how" className="jump_point"></a>
        <div className="max-w-5xl mx-auto flex px-5">
          <a href={LinkBeginner} className="md:text-base text-xs text-center py-4 px-2 md:px-10 w-1/2 bg-white mr-3 font-semibold border-b-[15px] border-white text-[#1F55A6] ">Just tradeの特徴</a>      
          <a href={LinkAbout} className="md:text-base text-xs text-center py-4 px-2 md:px-10 w-1/2 bg-white ml-3 font-semibold border-b-[15px] border-[#F6F6F6] hover:text-[#1F55A6] text-[#999999] hover:border-white ">プロップファームとは？</a>
        </div>
      </div>
      
      {/* 特徴 */}
      <div className="max-w-xl mx-auto md:max-w-5xl mt-[70px] px-5">
        <h3 className="md:text-3xl text-2xl font-black mb-10 text-left  text-[#1F55A6]">Just tradeの特徴</h3>
        <ul className="list-decimal ml-10 mb-8 font-semibold">
          <li>Just tradeは日本発のプロップファームです。<br></br>
              Just tradeの認定トレーダーとして<span className="text-[#DF5F6A]  text-xl">最大4,000万円</span>の資金を運用し、<span className="text-[#DF5F6A]  text-xl">最大90％の利益</span>を得ることができるサービスです。
          </li>
          <li>運用資金は取引スタイルに合わせて、５種類の中からお選びいただけます。</li>
          <li>トレーダーが<span className="text-[#DF5F6A] text-xl">実際に支払うのはチャレンジにかかる費用のみ</span>です。<br></br>
              こちらは認定トレーダーに昇格後、成功報酬として加算されます。
          </li>
        </ul>
        <a href={LinkGuidance_two} className="mb-5 duration-300 rounded-full block md:mx-0 mx-auto text-center text-white font-black pt-[10px] pb-[9px] px-5 bg-[#1F55A6] text-[10px] max-w-[210px] hover:tracking-widest">評価プロセスについてはこちら</a>    
        <a href={LinkGuidance_cost} className="mb-[50px] duration-300 rounded-full block md:mx-0 mx-auto  text-center  text-white font-black pt-[10px] pb-[9px] px-5 bg-[#1F55A6]  text-[10px] max-w-[210px] hover:tracking-widest">チャレンジ費用についてはこちら</a>
        <div className="max-w-5xl mx-auto mb-[140px] md:flex block">
          <div className="md:w-1/2 bg-[#1F55A6]">
            <p className="text-center text-white font-bold pt-10">ー Just tradeのお金でトレード ー</p>
            <img src={beginnerImg01_1} alt=""></img></div>
          <div className="md:w-1/2 bg-[#F6F6F6]">
          <p className="text-center text-[#666] font-bold pt-10">ー 自分のお金でトレード ー</p>
            <img src={beginnerImg01_2} alt=""></img></div>
        </div>
       
        
        {/* 特徴詳細1*/}
        <div className="md:flex justify-between block mb-[70px]">
          <div className="mb-5">
            <h4 className="border-l-2 border-[#1F55A6] text-lg mb-5 pl-3 font-semibold">Just tradeの資金で最大4,000万円の取引可能</h4>
            <div className="block  md:hidden mb-5">
            <div className="rounded-full h-[180px] w-[180px] bg-[#F6F6F6] font-bold text-[#1F55A6] md:ml-10 mx-auto flex items-center ">
              <p className=" md:max-w-[150px] max-w-[120px] mx-auto"> <img className="mx-auto h-auto" alt="" src={toptokuchou01}/> </p>
            </div>
          </div>
            <p className="mb-5">Just tradeはチャレンジ費用によって取引資金や利益目標といった条件が異なる5つのチャレンジコースがあります。<br></br>
              運用できる取引資金は最も上位のクラスで2,000万円からですが、スケーリングプランの条件をクリアすることで最大4,000万円まで取引資金を増額することができます。
            </p>
            <a href={LinkGuidance_cost}  className=" mb-[20px] duration-300 rounded-full block md:mx-0 mx-auto  text-center  text-white font-black pt-[10px] pb-[9px] px-5 bg-[#1F55A6]  text-[10px] max-w-[250px] hover:tracking-widest">5つのチャレンジコースについてはこちら</a>
          </div>
          <div className="md:inline-block  hidden">
            <div className="rounded-full h-[180px] w-[180px] bg-[#F6F6F6] font-bold text-[#1F55A6] md:ml-10 mx-auto flex items-center ">
              <p className=" md:max-w-[150px] max-w-[120px] mx-auto"> <img className="mx-auto h-auto" alt="" src={toptokuchou01}/> </p>
            </div>
            <a href={LinkGuidance_cost}  className="mt-8 block md:hidden  mb-[20px] duration-300 rounded-full block  mx-auto  text-center  text-white font-black pt-[10px] pb-[9px] px-5 bg-[#1F55A6]  text-[10px] max-w-[250px] hover:tracking-widest">5つのチャレンジコースについてはこちら</a>
          </div>
        </div>
        
        {/* 特徴詳細2*/}
        <div className="md:flex justify-between block mb-[70px]">
          <div className="mb-5">
              <h4 className="border-l-2 border-[#1F55A6] text-lg mb-5 pl-3 font-semibold">取引に自己資金不要</h4>
              <div className="block  md:hidden mb-5">
            <div className="rounded-full h-[180px] w-[180px] bg-[#F6F6F6] font-bold text-[#1F55A6] md:ml-10 mx-auto flex items-center ">
            <p className=" md:max-w-[80px] max-w-[60px] mx-auto"> <img className="mb-2 mx-auto h-auto" alt="" src={toptokuchou02}/> </p>
            </div>
          </div>
              <p className="mb-5">Just tradeの資金を運用し取引を行うので、トレーダーの資金は一切かかりません。 また、個人で運用するには難しい大きな金額を元手に取引を行うことができるメリットがあります。 </p> 
              <a href={LinkGuidance_cost} className="mb-[20px] duration-300 rounded-full block md:mx-0 mx-auto  text-center  text-white font-black pt-[10px] pb-[9px] px-5 bg-[#1F55A6]  text-[10px] max-w-[250px] hover:tracking-widest">5つのチャレンジコースについてはこちら</a>       
             </div>
             <div className="md:inline-block  hidden">
            <div className="rounded-full h-[180px] w-[180px] bg-[#F6F6F6] font-bold text-[#1F55A6] md:ml-10 mx-auto flex items-center ">
              <p className=" md:max-w-[80px] max-w-[60px] mx-auto"> <img className="mb-2 mx-auto h-auto" alt="" src={toptokuchou02}/> </p>
            </div>
            
          </div>
        </div>
        
        {/* 特徴詳細3*/}
        <div className="md:flex justify-between block mb-[70px]">
          <div className="mb-5">
            <h4 className="border-l-2 border-[#1F55A6] text-lg mb-5 pl-3 font-semibold">損失責任ゼロ</h4>
            <div className="block  md:hidden mb-5">
            <div className="rounded-full h-[180px] w-[180px] bg-[#F6F6F6] font-bold text-[#1F55A6] md:ml-10 mx-auto flex items-center ">
            <p className=" md:max-w-[60px] max-w-[80px] mx-auto"> <img className="mx-auto h-auto" alt="" src={toptokuchou03}/></p>
            </div>
          </div>
            <p>取引で損失を出しても、トレーダーが損失の補填を行うことはありません。トレーダーが支払うのはエントリーの際にかかるチャレンジ費用のみです。</p>
          </div>
          <div className="md:inline-block  hidden">
            <div className="rounded-full h-[180px] w-[180px] bg-[#F6F6F6] font-bold text-[#1F55A6] md:ml-10 mx-auto flex items-center ">
              <p className=" md:max-w-[60px] max-w-[80px] mx-auto"> <img className="mx-auto h-auto" alt="" src={toptokuchou03}/> </p>
            </div>
          </div>
        </div>
        
        {/* 特徴詳細4*/}
        <div className="md:flex justify-between block mb-[70px]">
          <div className="mb-5">
            <h4 className="border-l-2 border-[#1F55A6] text-lg mb-5 pl-3 font-semibold">得られた利益の最大90%をトレーダーに還元</h4>
            <div className="block  md:hidden mb-5">
            <div className="rounded-full h-[180px] w-[180px] bg-[#F6F6F6] font-bold text-[#1F55A6] md:ml-10 mx-auto flex items-center ">
            <p className=" md:max-w-[90px] max-w-[90px] mx-auto"> <img className="mb-5 mx-auto h-auto" alt="" src={toptokuchou04}/> </p>
            </div>
          </div>
            <p>トレーダーはJust tradeの資金を運用して取引を行うので、発生した利益の一部を受け取ることができます。<br></br>Just tradeではトレーダーへの利益還元率80％～90％という高い還元率を設定しています。</p>
          </div>
          <div className="md:inline-block  hidden">
            <div className="rounded-full h-[180px] w-[180px] bg-[#F6F6F6] font-bold text-[#1F55A6] md:ml-10 mx-auto flex items-center ">
              <p className=" md:max-w-[90px] max-w-[90px] mx-auto"> <img className="mb-5 mx-auto h-auto" alt="" src={toptokuchou04}/> </p>
            </div>
          </div>
        </div>
        
        {/* 特徴詳細5*/}
        <div className="md:flex justify-between block mb-[70px]">
          <div className="mb-5">
            <h4 className="border-l-2 border-[#1F55A6] text-lg mb-5 pl-3 font-semibold">認定トレーダーに昇格後にチャレンジ費用と同額が戻ってくる</h4>
            <div className="block  md:hidden mb-5">
            <div className="rounded-full h-[180px] w-[180px] bg-[#F6F6F6] font-bold text-[#1F55A6] md:ml-10 mx-auto flex items-center ">
            <p className=" md:max-w-[80px] max-w-[70px] mx-auto"> <img className="mx-auto h-auto" alt="" src={toptokuchou05}/> </p>
            </div>
          </div>
            <p>認定トレーダーに昇格後、1回目の成功報酬の支払い時にチャンレジ費用と同額を報奨金として取引資金に加算されます。</p>
          </div>
          <div className="md:inline-block  hidden">
            <div className="rounded-full h-[180px] w-[180px] bg-[#F6F6F6] font-bold text-[#1F55A6] md:ml-10 mx-auto flex items-center ">
              <p className=" md:max-w-[80px] max-w-[70px] mx-auto"> <img className="mx-auto h-auto" alt="" src={toptokuchou05}/> </p>
            </div>
          </div>
        </div>
        
      </div>

    
      
      {/* 固定フッター */}
      {fixedItem}
    </Layout>
  </>
  )
}
export default PropBeginner

// モーダル用funciton
export function ModalItem( item ) {

  // (※変更不要)モーダル用設定
  const[isModalOpen,setIsModalOpen]=useState(false)
  const closeModal= useCallback(() =>{
    setIsModalOpen(false)
    document.removeEventListener('click',closeModal)
  },[])
  useEffect(()=>{
    return ()=>{
      document.removeEventListener('click',closeModal)
    }
  },[closeModal])
  function openModal(event){
    setIsModalOpen(true)
    document.addEventListener('click',closeModal)
    event.stopPropagation()
  }

  // 子コンポーネント（モーダル）
  function Modal(props){
    const startYOffset = React.useRef(UseScrollPosition())
    startYOffset.current = window.pageYOffset // スクロール位置の左上のY軸座標
    // 調整　モーダル中身（テキスト以外）の設定
    return(
      <div id="modal" className="modal !z-20 text-black w-full text-right" onClick={(event)=>{event.stopPropagation()}}>
        {/* ここでモーダル表示位置を調整 */}
        <div className="absolute left-1/2 w-full mt-[32%] max-w-[320px] -ml-[160px] touch-none" style={{top: startYOffset.current}}> 
          {/* 出力データ */}
          <div className="!text-base absolute w-full bg-white z-20 max-w-[326px]">{item}</div>
          {/* この中のエリア押すと閉じる、閉じるボタン用*/}
          <button onClick={props.onClick} className="w-[30px] h-[30px] bg-black top-0 z-20 relative">
            <div className="!text-base text-white">×</div>
          </button>
        </div>
        {/*この中のエリア押すと閉じる、透過背景用 */}
        <div className="">
          <button onClick={props.onClick} className="w-full bg-gray-700 absolute top-[0px] left-5 h-[8030px] z-10 opacity-50 touch-none"></button>
        </div>
      </div>
    )
  }
  // 親コンポーネント（ボタン）
  return (
    <>
      <div>
        <button onClick={(event)=>{openModal(event)}} className=""> 
          <span className={"" + (isModalOpen? " hidden" :"")}>
            <img alt="" src={hatena_icon}/> {/* 調整　？マークアイコンに差し替える */}
          </span>
          {isModalOpen? <Modal onClick={(event)=>{closeModal(event)}} /> :""}
        </button>
      </div>
    </>
  );
}

// (※変更不要)モーダルスクロール位置取得用function
function UseScrollPosition() {
  const isProcessing = useRef(false)
  const [positionY, setPositionY] = useState(0)
  useEffect(() => {
    const handler = () => {
      if (isProcessing.current) return
      isProcessing.current = true
      window.requestAnimationFrame(() => { // Window.requestAnimationFrame()でpositionYステートの更新を間引く
        isProcessing.current = false
        setPositionY(window.scrollY)
      })
    }
    document.addEventListener('scroll', handler, { passive: true }) // スクロールイベントの登録
    return () => { // スクロールイベントの解除
      document.removeEventListener('scroll', handler)
    }
  }, [])
  return positionY // 取得したスクロール量をreturn
}
